import { derived, writable, type Writable } from 'svelte/store';

export const innerWidth: Writable<number> = writable(0);
export const isMobile = derived(innerWidth, ($innerWidth) => $innerWidth < 768);
export const isDesktop = derived(
  innerWidth,
  ($innerWidth) => $innerWidth >= 768
);

export let loadingProgress = writable(0); // Writable store to track loading progress
